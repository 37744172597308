import { apiHttpService } from '@/api'
import getNested from 'get-nested'
import { getWorkspaceId } from '@/api/utils/route_params'

const messagesContext = {
  receivedFile: 'received-files',
  document: 'documents',
  integrationFile: 'integration-files'
}

const processApiResponse = results => results.map(result => {
  return {
    id: result.id,
    code: result.code,
    level: result.level,
    context: result.context,
    createdAt: result.created_at,
    objectId: result.object_id,
    objectType: result.object_type,
    pipeline: result.pipeline,
    category: getNested(() => result.step.category, 'N/A')
  }
})

const fetchData = async (param) => {
  const url = `/workspaces/${getWorkspaceId()}/${messagesContext[param.content]}/${param.id}/step-messages`

  try {
    const response = await apiHttpService({
      url: url,
      method: 'GET',
      params: {
        page: 1,
        limit: 100
      }
    })
    const result = {
      content: param.content,
      messages: processApiResponse(response.data.results)
    }
    return Promise.resolve(result)
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  getMessages (params = []) {
    try {
      return fetchData(params)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
