<template>
  <v-timeline dense>
    <v-timeline-item
      small
      fill-dot
    >
      <v-card-actions>
        <refresh-data-button
          :loading-data="loading > 0"
          @refreshData="getMessageHistory"
        />
      </v-card-actions>
    </v-timeline-item>
    <v-data-iterator
      :items="allMessages"
      hide-default-footer
      disable-pagination
    >
      <template v-slot:item="props">
        <v-timeline-item
          small
          fill-dot
          :color="getItemColor(props.item)"
          :icon="getItemIcon(props.item)"
        >
          <v-card-title class="text-subtitle-1 pb-0">
            {{ $t('messages.code.' + props.item.code, props.item.context) }}
          </v-card-title>
          <v-card-text>
            <div
              v-if="isSchemaError(props.item)"
              class="text-caption"
            >
              {{ $t('steps.path') }}: {{ formatSchemaPath(props.item.context.path) }}
            </div>
            <div
              v-if="isSchemaError(props.item)"
              class="text-caption"
            >
              {{ $t('steps.error') }}: {{ props.item.context.error_message }}
            </div>
            <div class="text-caption">
              {{ $t('steps.table.header.id') }}: <span class="font-weight-medium">{{ props.item.id }}</span>
            </div>
            <div class="text-caption">
              {{ $t('steps.table.header.step') }} <span class="font-weight-medium">{{ $t('steps.names.' + props.item.category) }}</span>
              {{ $t('steps.table.header.date') }} <span class="font-weight-medium">{{ getDateTimeValue(props.item.createdAt, 'long') }}</span>
            </div>
            <div class="text-caption">
              {{ $t('steps.table.header.pipeline') }}: <span class="font-weight-medium">{{ props.item.pipeline.name }}</span>
            </div>
          </v-card-text>
        </v-timeline-item>
      </template>
      <template v-slot:no-data>
        <v-timeline-item
          small
          fill-dot
          color="grey lighten-1"
        >
          <v-card-title class="text-subtitle-1">
            {{ $t('general.noMessages') }}
          </v-card-title>
          <v-card-text>
            <div class="text-caption">
              {{ $t('general.refreshMessagesHint') }}
            </div>
          </v-card-text>
        </v-timeline-item>
      </template>
    </v-data-iterator>
  </v-timeline>
</template>

<script>
import messagesApi from '@/api/modules/steps/messages'
import { getDateTimeValue } from '@/mixins/dates'
import RefreshDataButton from '@/components/buttons/RefreshDataButton'

export default {
  name: 'MessageHistory',
  components: {
    RefreshDataButton
  },
  mixins: [getDateTimeValue],
  props: {
    params: { type: Array, default: () => { return [] } }
  },
  data () {
    return {
      loading: 0,
      messages: {
        receivedFile: [],
        document: [],
        integrationFile: []
      }
    }
  },
  computed: {
    allMessages () {
      return Object.keys(this.messages).reduce((acc, current) => {
        return acc.concat(this.messages[current])
      }, [])
    }
  },
  mounted () {
    this.getMessageHistory()
  },
  methods: {
    isSchemaError (message) {
      return message.code === 'json-schema-validation-error'
    },
    formatSchemaPath (path) {
      return path.replace(/[ [\]']/g, '').replace(/,/g, '.')
    },
    getMessageHistory () {
      const cleanParams = this.params.filter(param => param.id)
      cleanParams.forEach(param => this.getMessagesList(param))
    },
    getItemColor (item) {
      let color = 'success'

      if (item.level === 'warning') color = 'warning'
      if (item.level === 'error') color = 'error'

      return color
    },
    getItemIcon (item) {
      let icon = this.$vuetify.icons.values.check

      if (item.level === 'warning') icon = this.$vuetify.icons.values.warning
      if (item.level === 'error') icon = this.$vuetify.icons.values.error

      return icon
    },
    async getMessagesList (param) {
      this.loading++
      const result = await messagesApi.getMessages(param)
      this.messages[result.content] = [...result.messages]
      this.loading--
    }
  }
}
</script>
